import React, {useState} from 'react';
import './App.css';

import Jitsi from 'react-jitsi'

function App() {
  const [displayName, setDisplayName] = useState('')
  const [roomName, setRoomName] = useState('')
  const [onCall, setOnCall] = useState(false)

  return onCall
    ? (
      <Jitsi
        roomName={roomName}
        displayName={displayName}
        //password="123"
        domain="meet.jit.si"
      //  onAPILoad={JitsiMeetAPI => console.log('Good Morning everyone!')}
      />)
    : (
      <>
        <h1>Create a Meeting</h1>
        <input type='text' placeholder='Room name' value={roomName} onChange={e => setRoomName(e.target.value)} />
        <input type='text' placeholder='Your name' value={displayName} onChange={e => setDisplayName(e.target.value)} />
        <button onClick={() => setOnCall(true)}> Let&apos;s start!</button>
      </>
    )
}

export default App;
